import React, { useState } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './categoryRecipeCard.module.scss'
import { useLocalPath } from '../hooks/useLocalPath'
import RecipeModal from '../Modal/RecipeModal'
import { useLocale } from '../../fragments/hooks/useLocale'

const CategoryRecipeCard = ({data}) => {
    
    const {slug, thumbnailImage, thumbnailTitle, recipeType, sections, recipePopupChecker} = data

    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(null)

    const toggleModal = (data) => {
      setShowModal(prevShowModal => !prevShowModal)
      !showModal ? setModalData(data) : setModalData(null)
      
    }

    const locale = useLocale();

  return (
    <>
    <Link to = {slug ? useLocalPath(slug) : ''} className={styles.cardWrapper}>
        <div className={styles.card}>
            <div className={styles.cardImage}>
                <GatsbyImage
                    image={thumbnailImage?.gatsbyImageData}
                    alt={`${thumbnailImage?.title} image`}
                    loading="lazy"
                />
            </div>
            <div className={styles.cardInfo}>
                <p className={styles.type}>{recipeType}</p>
                <div className={styles.cardTitle}> {thumbnailTitle} </div>
                {!slug ? 
                   <button className={styles.recipeBtn} onClick={()=>toggleModal(sections)}>{locale == 'fr-CA' ? 'VOIR RECETTE' : 'View Recipe'}</button> 
                   : null
                }
            </div>
        </div>
    </Link>
     {
        (locale == 'en-CA' || locale == 'fr-CA') && showModal ? <RecipeModal onClose={toggleModal} recipePopupChecker={recipePopupChecker}  modalData={modalData} show={showModal}/> : null
     }
    </>
  )
}

export default CategoryRecipeCard